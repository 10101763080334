.align__center {
  text-align: -webkit-center;
}

/* KEYFRAMES */
@keyframes progress {
  0% {
    background-color: #ecf0f1;
  }
  50% {
    background-color: #e0e5e9;
  }
  100% {
    background-color: #ecf0f1;
  }
}

.image__skeleton {
  background-color: #ecf0f1;
  width: 300px;
  height: 150px;
  border-radius: 0.25rem;
  animation: progress 1.2s ease-in-out infinite;
}

.name__skeleton {
  background-color: #ecf0f1;
  width: 300px;
  height: 56px;
  border-radius: 0.25rem;
  margin-top: 3px;
  margin-bottom: 20px;
  animation: progress 1.2s ease-in-out infinite;
}

/* MEDIA QUERIES */
@media screen and (max-width: 768px) {
  .image__skeleton {
    width: 100px;
    height: 50px;
  }
  .name__skeleton {
    width: 100px;
    height: 25px;
  }
}
@media screen and (max-width: 320px) {
  .image__skeleton {
    width: 80px;
  }
  .name__skeleton {
    width: 80px;
  }
}
