.font__size__14 {
  font-size: 0.875rem;
}

.learn__more__btn {
  border: 2px solid #855e22;
  color: #855e22;
}

.learn__more__btn:hover {
  color: #855e22;
  text-decoration: none;
  background-color: #ebe6df;
}

.OuterWrap {
  position: relative;
}

.Swiper {
  padding-left: 30px;
  padding-right: 30px;
}

.PrevBtn {
  position: absolute;
  top: 50%;
  /* background: white; */
  /* border-radius: 100px; */
  outline: none;
  height: 25px;
  width: 25px;
  color: rgb(170, 165, 165);
  display: inline-block;
  text-align: center;
  text-decoration: none;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  cursor: pointer;
}

.NextBtn {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  text-align: center;
  top: 50%;
  text-decoration: none;
  right: -4px;
  /* background: white; */
  height: 25px;
  width: 25px;
  color: rgb(170, 165, 165);
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.PrevBtn:hover{
  color: rgb(133, 129, 129);
}

.NextBtn:hover{
  color: rgb(133, 129, 129);
}
