.font__size__12 {
  font-size: 0.75rem;
}
.font__size__14 {
  font-size: 0.875rem;
}

.font__size__24 {
  font-size: 1.5rem;
}

.cookName > a:hover {
  color: var(--dark-golden);
}

/* --------- MEDIA QUERIES ------------- */

@media screen and (max-width: 767px) {

  .roundedCircle {
    width: 80px;
    height: 80px;
  }
  .cookCard {
    /* width: 100%;
    height: 100% */
  }
  .cookName {
    font-size: 0.9rem;
  }
  .font__size__14 {
    font-size: 0.7rem;
  }  
}


