.homeCard {
}

.image {
  border-radius: 0.6rem;
  padding: 0.313rem;
}
.img {
  border-radius: 0.6rem;
  transition: all 0.5s;
  margin: 0 12px;
  padding-top: 10px;
  padding-bottom: 5px;
}
.img:hover {
  opacity: 0.9;
  transform: scale(1.1);
}

.cursor__pointer {
  cursor: pointer;
}

/* --------------------- Media Queries --------------------- */

@media screen and (max-width: 768px) {
  .name__font__size {
    font-size: 0.75rem; /* 12px*/
  }

  .img {
    margin: 0 2px;
  }
}
@media screen and (max-width: 320px) {
  .name__font__size {
    font-size: 0.625rem; /* 10px*/
  }
}
