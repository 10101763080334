.dropanote__strip {
  background-color: #ffc107 !important;
  width: 100% !important;
  margin-left: 0px;
}

.dropanote__item {
  color: black;
  font-size: 35px;
  text-align: center;
}

.clickable {
  color: #007bff;
}

.clickable:hover {
  color: #0056b3;
  text-decoration: underline;
  cursor: pointer;
}
