.learn__more__btn {
  border: 2px solid #855e22;
  color: #855e22;
}

.learn__more__btn:hover {
  color: #855e22;
  text-decoration: none;
  background-color: #ebe6df;
}
.font__size__14 {
  font-size: 0.875rem;
}
